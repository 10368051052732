.App{
  background: rgb(250, 250, 250);
}


.container{
  width: 100%;
  max-width: 1200px;
  height: 570px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}

.container .card{
  width: 400px;
  height: 570px;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 20px;
  text-align: center;
  transition: all 0.25s;
}

.container .card:hover{
  transform: translateY(-15px);
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
}